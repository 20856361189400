var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"ft-light fz12 ft-color"},[_vm._v(_vm._s(_vm.formatDate(_vm.item.updateTime)))]),_c('div',{staticClass:"time-block mt10"},[_c('div',{staticClass:"flex-between"},[_c('div',{staticClass:"flex-start"},[_c('a-avatar',{staticClass:"avatar",attrs:{"slot":"avatar","src":_vm.item.operateUserAvatar},slot:"avatar"}),_c('div',{staticClass:"ml10"},[_c('span',[_vm._v(_vm._s(_vm.item.operateUserName))])])],1),_c('span',{staticClass:"status",class:{
          'status-fail': _vm.item.operateTypeName === '暂不受理',
          'status-back':
            _vm.item.operateTypeName === '回退给' ||
            _vm.item.operateTypeName === '撤回',
          'status-accpeted':
            _vm.item.operateTypeName === '处理完成' ||
            _vm.item.operateTypeName === '验收完成',
          'status-wait':
            _vm.item.operateTypeName === '交办给' ||
            _vm.item.operateTypeName === '上报给' ||
            _vm.item.operateTypeName === '保存草稿' ||
            _vm.item.operateTypeName === '创建',
        }},[_vm._v(_vm._s(_vm.item.operateTypeName.replace(/给/g, "")))])]),_c('div',{staticClass:"handle-info mt10 ft12 ft-light"},[_c('span',[_vm._v(_vm._s(_vm.item.operateTypeName)+" "+_vm._s(_vm.item.assignedUserName)+" "),(_vm.item.assignedUserName)?[_vm._v(" （"+_vm._s(_vm.item.assignedUserMobile)+"） ")]:_vm._e()],2),_c('p',{},[_vm._v(_vm._s(_vm.item.operateDesc))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }