<template>
  <a-modal
    title="信息提示"
    :visible="visible"
    :confirm-loading="confirmLoading"
    :centered="true"
    @ok="handleOk"
    @cancel="handleSelectCancel"
  >
    <div class="">{{ txt }}</div>
    <a-input
      :placeholder="placeholder"
      class="mt23"
      type="textarea"
      auto-size
      :maxLength="100"
      v-show="isInput"
      v-model="operateDesc"
      @change="change"
    />
  </a-modal>
</template>
<script>
import { Modal, Input } from "ant-design-vue";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    confirmLoading: {
      type: Boolean,
      default: false,
    },
    isInput: {
      type: Boolean,
      default: true,
    },
    txt: String,
    placeholder: String,
  },
  components: {
    AModal: Modal,
    AInput: Input,
  },
  data() {
    return {
      operateDesc: "",
    };
  },
  created() {},
  methods: {
    handleSelectCancel() {
      this.$emit("handleSelectCancel");
    },
    handleOk() {
      this.$emit("handleOk");
    },
    change() {
      this.$emit("update:operateDesc", this.operateDesc);
    },
  },
};
</script>