<template>
  <div>
    <span class="ft-light fz12 ft-color">{{
      formatDate(item.updateTime)
    }}</span>
    <div class="time-block mt10">
      <div class="flex-between">
        <div class="flex-start">
          <a-avatar
            class="avatar"
            slot="avatar"
            :src="item.operateUserAvatar"
          />
          <div class="ml10">
            <span>{{ item.operateUserName }}</span>
            <!-- <span class="ft-light block">{{ item.department }}</span> -->
          </div>
        </div>
        <span
          class="status"
          :class="{
            'status-fail': item.operateTypeName === '暂不受理',
            'status-back':
              item.operateTypeName === '回退给' ||
              item.operateTypeName === '撤回',
            'status-accpeted':
              item.operateTypeName === '处理完成' ||
              item.operateTypeName === '验收完成',
            'status-wait':
              item.operateTypeName === '交办给' ||
              item.operateTypeName === '上报给' ||
              item.operateTypeName === '保存草稿' ||
              item.operateTypeName === '创建',
          }"
          >{{ item.operateTypeName.replace(/给/g, "") }}</span
        >
      </div>
      <div class="handle-info mt10 ft12 ft-light">
        <span
          >{{ item.operateTypeName }} {{ item.assignedUserName }}
          <template v-if="item.assignedUserName">
            （{{ item.assignedUserMobile }}）
          </template></span
        >
        <p class="">{{ item.operateDesc }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { Avatar } from "ant-design-vue";
export default {
  components: { AAvatar: Avatar },
  name: "timelineCom",
  props: ["item"],
  methods: {},
};
</script>
<style lang="less" scoped>
.time-block {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px 12px;
  .handle-info {
    padding-left: 42px;
  }
}
</style>