<template>
  <div>
    <Top title="事项详情" />
    <div class="detail mb20 pt44">
      <div class="bg-block mt10 pdlr15 pb15">
        <div class="flex-center column">
          <span
            class="mt5 iconfont ft-org fz43"
            :class="{
              'icon-hps-appgongdanxiangqing-daichuli':
                detail.status === 'forHandle',
              'icon-hps-appdaiyanshou': detail.status === 'forAcceptance',
              'icon-hps-appgongdanxiangqing-yiwancheng':
                detail.status === 'completed',
              'ft-accpeted': detail.status === 'completed',
              'icon-hps-appgongdanxiangqing-chulishibai':
                detail.status === 'handledFailure',
              'ft-fail': detail.status === 'handledFailure',
              'icon-hps-appgongdanxiangqing-yichexiao':
                detail.status === 'withdrawn',
              'ft-back': detail.status === 'withdrawn',
            }"
          ></span>
          <span>{{ status[detail.status] }}</span>
          <span class="ft-light fz12 mt10">{{ detail.nextUserName }}</span>
        </div>
        <div class="flex-between ft-light fz12 mt30">
          <span class="ft-grey">编码</span>
          <span>{{ detail.orderId }}</span>
        </div>
        <div class="flex-between ft-light fz12 mt30">
          <span class="ft-grey">所属分类</span>
          <span
            >{{ detail.orderClassficationName }} |
            {{ detail.matterClassficationName }}</span
          >
        </div>
        <div class="flex-between ft-light fz12 mt30">
          <span class="ft-grey">紧急性</span>
          <span :class="{ 'ft-red': detail.matterPropertyName === '严重' }">{{
            detail.matterPropertyName
          }}</span>
        </div>
        <div v-show="isMore">
          <div class="flex-between ft-light fz12 mt30">
            <span class="ft-grey">上报人</span>
            <span>{{ detail.createName }}</span>
          </div>
          <div class="flex-between ft-light fz12 mt30">
            <span class="ft-grey">上报部门</span>
            <span>{{ detail.orderCreateDept }}</span>
          </div>
          <div class="flex-between ft-light fz12 mt30">
            <span class="ft-grey">上报时间</span>
            <span>{{ formatDate(detail.reportTime) }}</span>
          </div>
        </div>
        <div
          class="detail-more mt10 ft-light fg-grey"
          @click="isMore = !isMore"
        >
          更多<a-icon
            v-show="isMore"
            type="up"
            style="color: #bdbdbd; margin-left: 5px"
          />
          <a-icon
            v-show="!isMore"
            type="down"
            style="color: #bdbdbd; margin-left: 5px"
          />
        </div>
      </div>
      <div class="bg-block mt10 flex-center1">
        <a-tabs
          default-active-key="1"
          :tabBarGutter="40"
          @change="handleChange"
        >
          <a-tab-pane key="1" tab="上报内容"></a-tab-pane>
          <a-tab-pane key="2" tab="流程记录"></a-tab-pane>
          <a-tab-pane
            key="3"
            tab="处理记录"
            v-if="feReceiptList.length > 0"
          ></a-tab-pane>
        </a-tabs>
      </div>
      <div class="detail-info bg-block mt10 pdall" v-show="tabKey === '1'">
        <div class="border-bottom pb15">
          <h5>{{ detail.title }}</h5>
          <div class="flex-center mt15">
            <span class="iconfont icon-hps-appwentimiaoshu"></span>
            <span class="fz12 ml5">描述</span>
          </div>
          <p class="fz12 ft-light mt10 white-space" v-html="detail.des"></p>
        </div>
        <div class="border-bottom pb15">
          <div class="flex-center mt15">
            <span class="iconfont icon-hps-appjianyijiqiwang"></span>
            <span class="fz12 ml5">建议及整改期望</span>
          </div>
          <p class="fz12 ft-light mt10 white-space" v-html="detail.advice"></p>
        </div>
        <div class="pb15" :class="{ 'border-bottom': detail.pictureUrl }">
          <div class="flex-center mt15">
            <span class="iconfont icon-hps-appquyu"></span>
            <span class="fz12 ml5">发生区域</span>
          </div>
          <p class="fz12 ft-light mt10">{{ detail.areaLocation }}</p>
        </div>
        <div class="" v-if="detail.pictureUrl">
          <div class="flex-center mt15">
            <span class="iconfont icon-hps-apptupian"></span>
            <span class="fz12 ml5">图片</span>
          </div>
          <div
            class="images"
            v-viewer="{ movable: false, toolbar: false }"
            v-if="detail.pictureUrl"
          >
            <img
              v-for="item in imagesBefore"
              :key="item"
              :src="item"
              class="mt10"
              @click="show"
            />
          </div>
        </div>
      </div>
      <div class="mt10 pdall" v-show="tabKey === '2'">
        <a-timeline>
          <template v-for="(item, index) in handleList">
            <a-timeline-item
              v-if="
                index === 0 &&
                item.operateTypeName !== '保存草稿' &&
                item.operateTypeName !== '创建'
              "
              :key="item.id"
              color="red"
            >
              <span slot="dot" class="iconfont icon-hps-apprili01" />
              <timelineCom :item="item" />
            </a-timeline-item>
            <a-timeline-item
              v-else-if="
                item.operateTypeName !== '保存草稿' &&
                item.operateTypeName !== '创建'
              "
              :key="item.id"
            >
              <timelineCom :item="item"
            /></a-timeline-item>
          </template>
        </a-timeline>
      </div>
      <div
        v-for="(feReceipt, index) in feReceiptList"
        :key="feReceipt.id"
        v-show="tabKey === '3'"
      >
        <div class="detail-info bg-block mt10 pdall">
          <div class="pb15">
            <div class="flex-center flex-between">
              <span class="fz12 ft-light">{{
                formatDate(feReceipt.createTime)
              }}</span>
              <!-- <a-icon type="down" style="color: #bdbdbd" /> -->
            </div>
            <div class="flex-between ft-light fz12 mt15">
              <span class="ft-grey">处理人</span>
              <span
                >{{ feReceipt.createName }}（{{
                  feReceipt.createMobile
                }}）</span
              >
            </div>
          </div>
          <div class="border-top pb15">
            <div class="flex-center mt15">
              <span class="iconfont icon-hps-appjianyijiqiwang"></span>
              <span class="fz12 ml5">处理描述</span>
            </div>
            <p class="fz12 ft-light mt10 white-space">
              {{ feReceipt.receiptDesc }}
            </p>
          </div>
          <div class="border-top" v-show="feReceipt.receiptPictureUrl">
            <div class="flex-center mt15">
              <span class="iconfont icon-hps-apptupian"></span>
              <span class="fz12 ml5">图片</span>
            </div>
            <div>
              <vuer :images="imagesAfter[index]">
                <img v-for="src in imagesAfter[index]" :src="src" :key="src" />
              </vuer>
            </div>
            <!-- <div class="images" v-viewer="{ movable: false, toolbar: false }">
            <img
              v-for="item in imagesAfter"
              :key="item"
              :src="item"
              :onerror="defaultImg"
              class="mt10"
              @click="show"
            /> -->
            <!-- </div> -->
          </div>
        </div>
      </div>
      <div
        class="handle-btn border-top"
        v-if="detail.status === 'forHandle' && detail.currentPersonType == '2'"
      >
        <a-dropdown
          :trigger="['click']"
          class="menu-btn"
          :getPopupContainer="
            (triggerNode) => {
              return triggerNode.parentNode || document.body;
            }
          "
        >
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            <a-icon type="unordered-list" style="font-size: 24px" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a href="javascript:;" @click="enterFn">处理过程</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="backToUser">回退</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="handlFail">暂不受理</a>
            </a-menu-item>
            <a-menu-item>
              <a
                href="javascript:;"
                @click="$router.push(`/report?edit=true&&id=${id}`)"
                >修改</a
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-button class="save" @click="completed">处理完成</a-button>
        <a-button class="submit" @click="handOverTo">交办给</a-button>
      </div>
      <div
        class="handle-btn"
        v-if="
          detail.status === 'forAcceptance' && detail.currentPersonType == '1'
        "
      >
        <a-button class="save" @click="backToUser">回退</a-button>
        <a-button class="submit" @click="sureCompleted">确认完成</a-button>
      </div>
      <div
        class="handle-btn"
        v-show="!isScore"
        v-if="detail.status === 'completed' && detail.currentPersonType == '1'"
      >
        <a-button class="submit" @click="score">评分</a-button>
      </div>
      <div
        class="handle-btn"
        v-if="detail.status === 'forHandle' && detail.currentPersonType == '1'"
      >
        <a-button class="submit" @click="revoke">撤销</a-button>
      </div>
      <div
        class="handle-btn"
        v-if="
          (detail.status === 'withdrawn' || detail.status === 'backspaced') &&
          detail.currentPersonType == '1'
        "
      >
        <a-button
          class="submit"
          @click="$router.push(`/report?reSub=true&&id=${id}`)"
          >重新提交</a-button
        >
      </div>
      <UserModal
        :visible="visible"
        :confirmLoading="confirmLoading"
        @handleOk="handleOk"
        @handleSelectChange="handleSelectChange($event)"
        @handleDepSelectChange="handleDepSelectChange($event)"
        @handleSelectCancel="handleSelectCancel"
        :isNoUser="isNoUser"
        :errorTxt="errorTxt"
        :isInput="true"
        :operateDesc.sync="operateDesc"
      />
      <BaseModal
        :visible="baseVisible"
        :confirmLoading="baseConfirmLoading"
        @handleOk="baseHandleOk"
        @handleSelectCancel="baseHandleSelectCancel"
        :txt="txt"
        :operateDesc.sync="operateDesc"
        :placeholder="placeholder"
        :isInput="isInput"
      />
      <a-drawer
        title="评分"
        placement="bottom"
        :closable="true"
        :visible="drawerVisible"
        @close="onClose"
        wrapClassName="rateDrawer"
      >
        <div class="pb15">
          <span class="mr10">综合评分</span>
          <a-rate v-model="rate" :tooltips="rateDesc" />
        </div>
        <div class="flex-center">
          <span>对这次事项处理效果，我想说：</span
          ><span class="status status-wait" v-show="rateDesc[rate - 1]">{{
            rateDesc[rate - 1]
          }}</span>
        </div>
        <div style="position: relative">
          <a-input
            v-model="advice"
            type="textarea"
            @change="descInput"
            :maxLength="200"
            class="mt10"
            placeholder="描述一下处理的感受，便于我们知晓处理情况"
          />
          <span class="right-tip">{{ descLength }}/200</span>
        </div>
        <div class="mt10">
          <a-button type="primary" style="width: 100%" @click="evaluate"
            >提交</a-button
          >
        </div>
      </a-drawer>
    </div>
  </div>
</template>
<script>
import {
  assignOrder,
  getDetail,
  // handleSuccess,
  acceptSuccess,
  backspace,
  evaluate,
  withdrawOrder,
  handleFailure,
} from "@/utils/api";
import {
  Icon,
  Tabs,
  Timeline,
  Button,
  Dropdown,
  Menu,
  Drawer,
  Rate,
  Input,
} from "ant-design-vue";
import timelineCom from "@/components/order/timelineCom";
import UserModal from "@/components/modal/userModal";
import BaseModal from "@/components/modal/baseModal";
import Top from "@/components/top/top";
import "viewerjs/dist/viewer.css";
import { directive as viewer } from "v-viewer";
import Vuer from "v-viewer";
import Vue from "vue";
Vue.use(Vuer, { name: "vuer" });
Vuer.setDefaults({ movable: false, toolbar: false });
export default {
  components: {
    AIcon: Icon,
    ATabs: Tabs,
    AButton: Button,
    ATabPane: Tabs.TabPane,
    ATimeline: Timeline,
    ATimelineItem: Timeline.Item,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ADrawer: Drawer,
    AInput: Input,
    ARate: Rate,
    timelineCom,
    UserModal,
    BaseModal,
    Top,
  },
  directives: {
    viewer: viewer({
      debug: true,
    }),
  },
  data() {
    return {
      id: this.$route.query.id,
      isMore: false,
      tabKey: "1",
      isNoUser: false,
      errorTxt: "请选择交办人",
      visible: false,
      confirmLoading: false,
      baseVisible: false,
      drawerVisible: false,
      baseConfirmLoading: false,
      isInput: true,
      txt: "",
      isScore: false,
      operateDesc: "",
      placeholder: "",
      detail: {},
      feReceiptList: {
        createName: "",
        createTime: "",
        createMobile: "",
        receiptDesc: "",
      },
      rate: 0,
      rateDesc: ["非常不满意", "不满意", "一般", "满意", "非常满意"],
      advice: "",
      status: {
        forHandle: "处理中",
        forAcceptance: "正在验收",
        completed: "已完成",
        handledFailure: "暂不受理",
        withdrawn: "已撤销",
      },
      handleList: [],
      oldScrollTop: 0,
      scrollTop: 0,
      imagesBefore: [],
      imagesAfter: [],
      descLength: 0,
      rateAct: {
        1: "-2",
        2: "-1",
        3: "0",
        4: "1",
        5: "2",
      },
    };
  },
  created() {
    this.getDetail();
  },
  computed: {
    // defaultImg() {
    //   return `this.src="${require("@/assets/img/default.png")}"`;
    // },
  },
  watch: {
    scrollTop(newVal, oldVal) {
      setTimeout(() => {
        if (newVal == window.scrollY) {
          // console.log("滚动结束");

          this.oldScrollTop = newVal;
        }
        if (this.oldScrollTop == oldVal) {
          //每次滚动开始时oldScrollTop与oldValue相等
          console.log("滚动开始");
          if (this.scrollTop < 50) {
            this.isMore = false;
          }
          console.log(this.scrollTop);
        }
      }, 20);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    // this.handleScroll();
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
    // window.removeEventListener("scroll");
  },
  methods: {
    show() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },
    //描述文字长度
    descInput() {
      let length = this.advice.length;
      if (length > 200) {
        this.descLength = 200;
        return;
      }
      this.descLength = length;
    },
    //录入处理
    enterFn() {
      // let feReceipt = JSON.stringify(this.feReceipt);
      this.$router.push(`/enter?orderId=${this.detail.orderId}`);
      // localStorage.setItem("feReceipt", feReceipt);
    },
    //暂不受理
    async handleFailure() {
      const { resp_code, resp_msg } = await handleFailure({
        id: this.id,
        operateDesc: this.operateDesc,
      });
      if (resp_code === 0) {
        this.baseConfirmLoading = false;
        this.baseVisible = false;
        this.$message.success("操作成功", 2, () => {
          this.$router.push("/order?index=2");
        });
      }
    },
    //撤销
    async withdrawOrder() {
      const { resp_code, resp_msg } = await withdrawOrder({
        id: this.id,
        operateDesc: this.operateDesc,
      });
      if (resp_code === 0) {
        this.baseConfirmLoading = false;
        this.baseVisible = false;
        this.$message.success("操作成功", 2, () => {
          location.reload();
        });
      }
    },
    //回退
    async backspace() {
      const { resp_code, resp_msg } = await backspace({
        id: this.id,
        operateDesc: this.operateDesc,
      });
      if (resp_code === 0) {
        this.baseConfirmLoading = false;
        this.baseVisible = false;
        this.$message.success("操作成功", 2, () => {
          this.$router.push("/order?index=2");
        });
      } else {
        this.$message.error(resp_msg, 2, () => {
          this.baseConfirmLoading = false;
          this.baseVisible = false;
        });
      }
    },
    async getDetail() {
      this.imagesBefore = [];
      const { data } = await getDetail(this.id);
      this.detail = data;
      this.detail.des = this.detail.des.replace(/\\r\\n/g, "<br/>");
      this.detail.advice = this.detail.advice.replace(/\\r\\n/g, "<br/>");
      this.imagesBefore.push(data.pictureUrl);
      this.handleList = data.records;
      if (data.isEvaluate === 1) {
        this.isScore = true;
      }
      if (data.feReceiptList.length > 0) {
        this.imagesAfter = [];
        this.feReceiptList = data.feReceiptList;
        this.feReceiptList.forEach((item, index) => {
          this.imagesAfter[index] = [item.receiptPictureUrl];
        });
        // if (this.feReceipt.receiptPictureUrl) {
        //   this.imagesAfter.push(data.feReceipt.receiptPictureUrl);
        // }
      }
    },
    handleSelectChange(value) {
      this.nextUserId = value;
      this.isNoUser = false;
    },
    handleDepSelectChange(value) {
      this.orderBelongDept = value;
      // this.isNoUser = false;
    },
    handleChange(key) {
      this.tabKey = key;
    },
    //处理完成
    completed() {
      this.$router.push(`/finish?id=${this.detail.id}`);
      // this.modal = "completed";
      // this.baseVisible = true;
      // this.txt = "事项将标记为处理成功，进入验收环节，是否确认完成？";
      // this.placeholder = "请输入处理描述";
    },
    handOverTo() {
      this.visible = true;
    },
    handleSelectCancel(e) {
      this.visible = false;
    },
    handleOk() {
      if (!this.nextUserId) {
        this.isNoUser = true;
        this.errorTxt = "请选择交办人";
        return;
      }
      this.confirmLoading = true;
      this.submitOrder();
    },
    //回退
    backToUser() {
      this.modal = "back";
      this.baseVisible = true;
      this.txt = "回退后，事项将退回至上一操作人，是否确认回退？";
      this.placeholder = "请输入回退原因";
    },
    //暂不受理
    handlFail() {
      this.modal = "fail";
      this.baseVisible = true;
      this.txt = "事项将标记为暂不受理，是否确定关闭？";
      this.placeholder = "请输入暂不受理原因";
    },
    baseHandleOk() {
      this.baseConfirmLoading = true;
      if (this.modal === "completed") {
        this.handleSuccess();
      }
      if (this.modal === "sureCompleted") {
        this.acceptSuccess();
      }
      if (this.modal === "back") {
        this.backspace();
      }
      if (this.modal === "fail") {
        this.handleFailure();
      }
      if (this.modal === "revoke") {
        this.withdrawOrder();
      }
    },
    baseHandleSelectCancel() {
      this.baseVisible = false;
    },
    //确认提交
    async submitOrder() {
      const { resp_code, resp_msg } = await assignOrder({
        id: this.id,
        nextUserId: this.nextUserId,
        orderBelongDept: this.orderBelongDept,
        operateDesc: this.operateDesc,
      });
      if (resp_code === 0) {
        this.confirmLoading = false;
        this.visible = false;
        this.$message.success("交办成功", 2, () => {
          this.$router.push("/order?index=2");
        });
        // this.$router.push("/result");
      } else {
        this.confirmLoading = false;
        this.isNoUser = true;
        this.errorTxt = resp_msg;
      }
    },
    // async handleSuccess() {
    //   const { resp_code, resp_msg } = await handleSuccess({
    //     id: this.id,
    //     operateDesc: this.operateDesc,
    //   });
    //   if (resp_code === 0) {
    //     this.baseConfirmLoading = false;
    //     this.baseVisible = false;
    //     this.$message.success("操作成功", 2, () => {
    //       this.$router.push("/order?index=2");
    //     });
    //   } else {
    //     this.baseConfirmLoading = false;
    //   }
    // },
    async acceptSuccess() {
      const { resp_code, resp_msg } = await acceptSuccess({ id: this.id });
      if (resp_code === 0) {
        this.baseConfirmLoading = false;
        this.baseVisible = false;
        this.$message.success("操作成功", 2, () => {
          // this.$router.go("-1");
          this.getDetail();
          this.score();
        });
      } else {
        this.baseConfirmLoading = false;
      }
    },
    //确认完成
    sureCompleted() {
      this.modal = "sureCompleted";
      this.baseVisible = true;
      this.txt = "您提交的事项是否已完成处理？";
      this.isInput = false;
    },
    //评分
    score() {
      this.drawerVisible = true;
    },
    async evaluate() {
      if (this.rate == 0) {
        this.$message.warning("请打分");
        return;
      }
      const { resp_code, resp_msg } = await evaluate({
        orderId: this.detail.orderId,
        score: this.rateAct[this.rate],
        content: this.advice,
      });
      if (resp_code === 0) {
        this.drawerVisible = false;
        this.$message.success("评价成功", 2, () => {
          // this.$router.go("-1");
          this.isScore = true;
        });
      } else {
        this.$message.error(resp_msg);
      }
    },
    onClose() {
      this.drawerVisible = false;
    },
    //撤销
    revoke() {
      this.modal = "revoke";
      this.baseVisible = true;
      this.txt = "撤销后，事项将关闭处理，是否确认撤销？";
      this.isInput = false;
    },
    //
    handleScroll() {
      window.addEventListener("scroll", () => {
        this.scrollTop = window.scrollY;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.detail {
  padding-bottom: 5rem;
}
.detail-more {
  text-align: center;
}
.detail-info {
  h5 {
    line-height: 1.8rem;
  }
  img {
    width: 60px;
    height: 60px;
    margin-right: 6px;
  }
}
.white-space {
  white-space: pre-wrap;
}
.right-tip {
  color: #bdbdbd;
  font-size: 1.2rem;
  position: absolute;
  right: 8px;
  bottom: 8px;
}
</style>